import api from '@/services/api'
import auth from '@/store/modules/auth'

export default {
  state: {},

  mutations: {},

  actions: {
    async getEvolution({ commit }, data) {
      try {
        const query = new URLSearchParams(data)
        const response = await api.get(`/portal/news/evolutions?${query}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getInformatives({ commit }, data) {
      try {
        const query = new URLSearchParams(data)
        const response = await api.get(`/portal/news/informatives?${query}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getConstruction({ commit }, data) {
      try {
        const query = new URLSearchParams(data)
        const response = await api.get(`/portal/news/constructions?${query}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getConstructionData({ commit }, uuid) {
      try {
        const response = await api.get(`/portal/news/constructions/${uuid}`)
        if (response && response.changeUnity) {
          commit('SET_UNITY', response.unity)
          response.data.changeUnity = response.changeUnity
        }
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getInformativeData({ commit }, uuid) {
      try {
        const response = await api.get(`/portal/news/informatives/${uuid}`)
        if (response && response.changeUnity) {
          commit('SET_UNITY', response.unity)
          response.data.changeUnity = response.changeUnity
        }
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
