import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getFinancialGeneral({ commit }, unity) {
      try {
        const response = await api.get(`/portal/financials/generals`, {
          params: unity,
        })

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getFinancialBillets({ commit }, unity) {
      try {
        const response = await api.get(`/portal/financials/billets`, {
          params: unity,
        })

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getFinancialReadjustments({ commit }, unity) {
      try {
        const response = await api.get(`/portal/financials/readjustments`, {
          params: unity,
        })

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getBilletPDF({ commit }, billet) {
      try {
        const response = await api.get(`/portal/financials/billets/pdf`, {
          params: {
            id: billet.id,
            bankAgreement: billet.bankAgreement,
            idReport: billet.idReport,
            codColigada: billet.codColigada,
            codFilial: billet.codFilial,
          },
        })
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getIncomeTaxPDF({ commit }, data) {
      try {
        const response = await api.get(`/portal/financials/income-tax/pdf`, {
          params: {
            year: data.year,
            codPessEmpr: data.unity.codPessEmpr,
            numUnity: data.unity.numUnity,
            numSubUnity: data.unity.numSubUnity,
          },
        })
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getIncomeTaxYears({ commit }, unity) {
      try {
        const response = await api.get(`/portal/financials/income-tax/years`, {
          params: unity,
        })

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getFinancialStatements({ commit }, unity) {
      try {
        const response = await api.get(`/portal/financials/statements`, {
          params: unity,
        })

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
