import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import PATHS from './routesMap'

import Auth from '../views/auth/Auth.vue'

const routes = [
  {
    name: 'Auth',
    path: PATHS.AUTH,
    component: Auth,
  },
  {
    name: 'PasswordRecovery',
    path: PATHS.PASSWORD_RECOVERY,
    component: () =>
      import(
        /* webpackChunkName: "passwordRecovery" */ '../views/auth/PasswordRecovery.vue'
      ),
  },
  {
    name: 'NewPassword',
    path: PATHS.NEW_PASSWORD,
    component: () =>
      import(
        /* webpackChunkName: "newPassword" */ '../views/auth/NewPassword.vue'
      ),
  },
  {
    name: 'Home',
    path: PATHS.HOME,
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'Users',
    path: PATHS.USERS,
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/users/Users.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'UsersUnities',
    path: PATHS.UNITIES,
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/users/Unities.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'Documents',
    path: PATHS.DOCS,
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/documents/Documents.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'ExternalSupportTicket',
    path: PATHS.SUPPORT_TICKET_EXTERNAL,
    component: () =>
      import(
        /* webpackChunkName: "home" */ '../views/supports/NewTicketExternal.vue'
      ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: 'Financials',
    path: PATHS.FINANCIALS,
    component: () =>
      import(
        /* webpackChunkName: "financials" */ '../views/financials/Financial.vue'
      ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'FinancialsHome',
        path: PATHS.FINANCIALS,
        component: () =>
          import(
            /* webpackChunkName: "financials" */ '../views/financials/Home.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'FinancialsBillets',
        path: PATHS.FINANCIALS_BILLETS,
        component: () =>
          import(
            /* webpackChunkName: "financials" */ '../views/financials/Billets.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'FinancialsStatement',
        path: PATHS.FINANCIALS_STATEMENTS,
        component: () =>
          import(
            /* webpackChunkName: "financials" */ '../views/financials/Statements.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'FinancialsIncomeTax',
        path: PATHS.FINANCIALS_INCOME_TAX,
        component: () =>
          import(
            /* webpackChunkName: "financials" */ '../views/financials/IncomeTax.vue'
          ),
      },
      {
        name: 'FinancialsReadjustments',
        path: PATHS.FINANCIALS_READJUSTMENTS,
        component: () =>
          import(
            /* webpackChunkName: "financials" */ '../views/financials/Readjustments.vue'
          ),
      },
    ],
  },
  {
    name: 'Support',
    path: PATHS.SUPPORT,
    component: () =>
      import(/* webpackChunkName: "help" */ '../views/supports/Support.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'SupportHome',
        path: PATHS.SUPPORT,
        component: () =>
          import(
            /* webpackChunkName: "help" */ '../views/supports/SupportHome.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'SupportFaq',
        path: PATHS.SUPPORT_FAQ,
        component: () =>
          import(
            /* webpackChunkName: "help" */ '../views/supports/SupportFaq.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'NewTicketWarranties',
        path: PATHS.SUPPORT_NEW_TICKET_WARRANTIES,
        component: () =>
          import(
            /* webpackChunkName: "help" */ '../views/supports/NewTicketWarranty.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'TicketsList',
        path: PATHS.SUPPORT_TICKET_LIST,
        component: () =>
          import(
            /* webpackChunkName: "help" */ '../views/supports/SupportHome.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'TicketsNew',
        path: PATHS.SUPPORT_TICKET_NEW,
        component: () =>
          import(
            /* webpackChunkName: "help" */ '../views/supports/NewTicketGeneral.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'TicketDetails',
        path: PATHS.SUPPORT_TICKET_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "help" */ '../views/supports/TicketDetails.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  // Garantias
  {
    name: 'Warranties',
    path: PATHS.WARRANTIES,
    component: () =>
      import(
        /* webpackChunkName: "help" */ '../views/warranties/Warranties.vue'
      ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'WarrantiesHome',
        path: PATHS.WARRANTIES,
        component: () =>
          import(
            /* webpackChunkName: "help" */ '../views/warranties/WarrantiesHome.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  // Notícias
  {
    name: 'News',
    path: PATHS.NEWS,
    component: () =>
      import(/* webpackChunkName: "help" */ '../views/news/News.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'NewsHome',
        path: PATHS.NEWS,
        component: () =>
          import(/* webpackChunkName: "help" */ '../views/news/NewsHome.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'NewsConstructionsList',
        path: PATHS.NEWS_CONSTRUCTION,
        component: () =>
          import(
            /* webpackChunkName: "help" */ '../views/news/NewsConstructionsList.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'NewsConstructionsDetails',
        path: PATHS.NEWS_CONSTRUCTION_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "help" */ '../views/news/NewsConstructionsDetails.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'NewsInformativesList',
        path: PATHS.NEWS_INFORMATIVE,
        component: () =>
          import(
            /* webpackChunkName: "help" */ '../views/news/NewsInformativesList.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'NewsInformativesDetails',
        path: PATHS.NEWS_INFORMATIVE_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "help" */ '../views/news/NewsInformativeDetails.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    name: 'Unauthorized',
    path: PATHS.UNAUTHORIZED,
    component: () =>
      import(
        /* webpackChunkName: "unauthorized" */ '../views/unauthorized/Unauthorized.vue'
      ),
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),

  routes,
})

router.beforeEach((to, from) => {
  if (store.getters.isLoggedPdCIn && to.name === 'Auth') {
    return { name: 'Home' }
  }
  if (to.meta.requiresAuth) {
    if (!store.getters.isLoggedPdCIn && !localStorage.getItem('tokenPdC')) {
      return { name: 'Auth' }
    }
  }
})

router.afterEach(async (to, from) => {
  if (!store.getters.isLoggedPdCIn && to.meta.requiresAuth) {
    await store.dispatch('logout')
    router.push(PATHS.AUTH)
  }
})

export default router
