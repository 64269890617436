import api from '@/services/api'
import auth from '@/store/modules/auth'

export default {
  state: {},

  mutations: {},

  actions: {
    async getNotifications({ commit }) {
      try {
        const response = await api.get(`/portal/notifications`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
