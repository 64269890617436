<template>
  <div class="sidebar sidebar-dark sidebar-main sidebar-expand-lg">
    <div class="sidebar-content">
      <div class="sidebar-section">
        <div class="sidebar-section-body d-flex justify-content-center">
          <div class="sidebar-resize-hide flex-grow-1 my-auto">
            <div class="">
              <img
                src="@/assets/img/horizon_logo_white.png"
                alt=""
                class="pe-3 mb-2"
                height="70"
              />
            </div>
            <h6>{{ user.name }}</h6>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-flat-white btn-icon btn-sm rounded-pill border-transparent sidebar-control sidebar-main-resize d-none d-lg-inline-flex"
              title="Ocultar/Expandir"
            >
              <i class="ph-arrows-left-right"></i>
            </button>
            <button
              type="button"
              class="btn btn-flat-white btn-icon btn-sm rounded-pill border-transparent sidebar-mobile-main-toggle d-lg-none"
              title="Ocultar"
            >
              <i class="ph-x"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="sidebar-section">
        <ul class="nav nav-sidebar" data-nav-type="accordion">
          <li class="nav-item-header pt-0">
            <div
              class="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide"
            >
              Atalhos
            </div>
            <i class="ph-dots-three sidebar-resize-show"></i>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              data-bs-toggle="offcanvas"
              data-bs-target="#notifications"
            >
              <i class="ph-bell"></i>
              <span>Notificações</span>
              <span
                class="badge bg-primary align-self-center rounded-pill ms-auto"
                >{{ notifications.number }}</span
              >
            </a>
          </li>
          <li class="nav-item">
            <router-link
              to="/home"
              :class="`nav-link ${liHome}`"
              title="Página Inicial"
            >
              <i class="ph-house"></i>
              <span>Home</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="unity && unity.showFinancial">
            <router-link
              to="/financials"
              :class="`nav-link ${liFinancials}`"
              title="Financeiro"
            >
              <i class="ph-currency-dollar"></i>
              <span>Financeiro</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="unity && unity.showDocuments">
            <router-link
              to="/documents"
              :class="`nav-link ${liDocuments}`"
              title="Dcoumentos"
            >
              <i class="ph-file-text"></i>
              <span>Documentos</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="unity && unity.showNews">
            <router-link
              to="/news"
              :class="`nav-link ${liNews}`"
              title="Notícias"
            >
              <i class="ph-newspaper"></i>
              <span>Notícias</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/supports"
              :class="`nav-link ${liSupports}`"
              title="Ajuda"
            >
              <i class="ph-question"></i>
              <span>Atendimento</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="unity && unity.showWarranty">
            <router-link
              to="/warranties"
              :class="`nav-link ${liWarranties}`"
              title="Garantias"
            >
              <i class="ph-list-checks"></i>
              <span>Garantias</span>
            </router-link>
          </li>

          <li class="nav-item-header">
            <div
              class="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide"
            >
              Usuário
            </div>
            <i class="ph-dots-three sidebar-resize-show"></i>
          </li>
          <li class="nav-item">
            <router-link
              to="/users"
              :class="`nav-link ${liUsers}`"
              title="Minha Conta"
            >
              <i class="ph-user"></i>
              <span>Minha Conta</span>
            </router-link>
            <router-link to="/" class="nav-link" @click="logout" title="Sair">
              <i class="ph-sign-out"></i>
              <span>Sair</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'

export default {
  name: 'Sidebar',
  props: {
    notifications: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      PATHS,
      liHome: '',
      liFinancials: '',
      liDocuments: '',
      liNews: '',
      liSupports: '',
      liWarranties: '',
      liUsers: '',
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        localStorage.clear()
        this.$router.push(PATHS.BASE_URL)
      })
    },

    // Initialize all sidebars
    initSidebars() {
      this.sidebarMainResize()
    },
    // Resize main sidebar
    sidebarMainResize() {
      // Elements
      const sidebarMainElement = document.querySelector('.sidebar-main')
      const sidebarMainToggler = document.querySelectorAll(
        '.sidebar-main-resize',
      )
      const resizeClass = 'sidebar-main-resized'
      const unfoldClass = 'sidebar-main-unfold'

      // Config
      if (sidebarMainElement) {
        // Define variables
        const unfoldDelay = 150
        let timerStart
        let timerFinish

        // Toggle classes on click
        sidebarMainToggler.forEach(function (toggler) {
          toggler.addEventListener('click', function (e) {
            e.preventDefault()
            sidebarMainElement.classList.toggle(resizeClass)
            if (!sidebarMainElement.classList.contains(resizeClass))
              sidebarMainElement.classList.remove(unfoldClass)
          })
        })

        // Add class on mouse enter
        sidebarMainElement.addEventListener('mouseenter', function () {
          clearTimeout(timerFinish)
          timerStart = setTimeout(function () {
            if (sidebarMainElement.classList.contains(resizeClass))
              sidebarMainElement.classList.add(unfoldClass)
          }, unfoldDelay)
        })

        // Remove class on mouse leave
        sidebarMainElement.addEventListener('mouseleave', function () {
          clearTimeout(timerStart)
          timerFinish = setTimeout(function () {
            sidebarMainElement.classList.remove(unfoldClass)
          }, unfoldDelay)
        })
      }
    },
    updateSelectedSession() {
      const currentRouteName = this.$route.name

      if (!currentRouteName) return
      this.liHome = currentRouteName === 'Home' ? 'active' : ''
      this.liFinancials = currentRouteName.includes('Financials')
        ? 'active'
        : ''
      this.liDocuments = currentRouteName.includes('Documents') ? 'active' : ''
      this.liNews = currentRouteName.includes('News') ? 'active' : ''
      this.liSupports = currentRouteName.includes('Support') ? 'active' : ''
      this.liWarranties = currentRouteName.includes('Warrant') ? 'active' : ''
      this.liUsers = currentRouteName.includes('Users') ? 'active' : ''
    },
  },

  computed: {
    unity() {
      return this.$store.getters.unity
    },
    user() {
      return this.$store.getters.user
    },
    currentRouteName() {
      return this.$route.name
    },
  },
  watch: {
    currentRouteName() {
      this.updateSelectedSession()
    },
  },
  mounted() {
    this.initSidebars()
    this.updateSelectedSession()
  },
}
</script>
