import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getUnityAndUserInformation({ commit }, uuid) {
      try {
        const response = await api.get(
          `/clients/tickets/info-unity-and-client/${uuid}`,
        )
        return response
      } catch (error) {
        throw error
      }
    },

    async getSubjects({ commit }) {
      try {
        const response = await api.get(`/portal/tickets/subjects`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getTickets({ commit }, data) {
      try {
        const response = await api.get(`/portal/tickets?search=${data.search}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getTicketDetails({ commit }, data) {
      try {
        const response = await api.get(`/portal/tickets/${data}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getTicketInteractions({ commit }, data) {
      try {
        const response = await api.get(`/portal/tickets/${data}/interactions`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async selectOptionInteractionTicket({ commit }, data) {
      try {
        const response = await api.put(
          `/portal/tickets/${data.uuid}/interactions/${data.id}/options`,
          { options: data.data },
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async sendTicketMessage({ commit }, data) {
      try {
        return await api.post(
          `/portal/tickets/${data.uuid}/interactions`,
          data.formData,
        )
      } catch (error) {
        throw error
      }
    },
    async sendTicketAvaliation({ commit }, data) {
      try {
        return await api.post(`/portal/tickets/${data.uuid}/avaliation`, data)
      } catch (error) {
        throw error
      }
    },

    async openTicket({ commit }, form) {
      try {
        return await api.post(`/portal/tickets`, form)
      } catch (error) {
        throw error
      }
    },
    async getFaq({ commit }, data) {
      try {
        const response = await api.get(`/portal/faq?search=${data.search}`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
