<template>
  <div
    class="navbar navbar-expand-lg navbar-static border-bottom border-bottom-white border-opacity-10"
  >
    <div class="mx-2 w-100">
      <div class="d-flex justify-content-between">
        <button
          type="button"
          class="navbar-toggler sidebar-mobile-main-toggle rounded"
        >
          <i class="ph-list" />
        </button>
        <router-link
          v-for="(link, index) in buttons"
          :key="index"
          :to="link.to"
          type="button"
          class="btn btn-link navbar-toggler p-1"
          :title="link.title"
          v-show="link.show"
        >
          <div class="d-flex flex-column">
            <i :class="link.icon + ' mb-1'" />
            <p class="mb-0 fs-xs">{{ link.title }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'

export default {
  name: 'MenuMobile',

  components: {},

  data() {
    return {
      buttons: [
        // {
        //   to: '/financials',
        //   title: 'Financeiro',
        //   icon: 'ph-currency-dollar',
        //   show:
        //     this.$store.getters.unity &&
        //     this.$store.getters.unity.showFinancial,
        // },
        // {
        //   to: '/documents',
        //   title: 'Documentos',
        //   icon: 'ph-file-text',
        //   show: false,
        // },
        {
          to: '/users',
          title: 'Conta',
          icon: 'ph-user',
          show: true,
        },
      ],
    }
  },

  computed: {
    user() {
      return this.$store.getters.user
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        localStorage.clear()
        this.$router.push(PATHS.BASE_URL)
      })
    },
    // Initialize all sidebars
    initSidebars() {
      this.sidebarMainResize()
      this.sidebarMainToggle()
      this.sidebarComponentToggle()
    },

    // Initialize all navigations
    initNavigations() {
      this.navigationSidebar()
    },

    // Resize main sidebar
    sidebarMainResize() {
      // Elements
      const sidebarMainElement = document.querySelector('.sidebar-main')
      const sidebarMainToggler = document.querySelectorAll(
        '.sidebar-main-resize',
      )
      const resizeClass = 'sidebar-main-resized'
      const unfoldClass = 'sidebar-main-unfold'

      // Config
      if (sidebarMainElement) {
        // Define variables
        const unfoldDelay = 150
        let timerStart
        let timerFinish

        // Toggle classes on click
        sidebarMainToggler.forEach(function (toggler) {
          toggler.addEventListener('click', function (e) {
            e.preventDefault()
            sidebarMainElement.classList.toggle(resizeClass)
            if (!sidebarMainElement.classList.contains(resizeClass))
              sidebarMainElement.classList.remove(unfoldClass)
          })
        })

        // Add class on mouse enter
        sidebarMainElement.addEventListener('mouseenter', function () {
          clearTimeout(timerFinish)
          timerStart = setTimeout(function () {
            if (sidebarMainElement.classList.contains(resizeClass))
              sidebarMainElement.classList.add(unfoldClass)
          }, unfoldDelay)
        })

        // Remove class on mouse leave
        sidebarMainElement.addEventListener('mouseleave', function () {
          clearTimeout(timerStart)
          timerFinish = setTimeout(function () {
            sidebarMainElement.classList.remove(unfoldClass)
          }, unfoldDelay)
        })
      }
    },

    // Toggle main sidebar
    sidebarMainToggle() {
      // Elements
      const sidebarMainElement = document.querySelector('.sidebar-main')
      const sidebarMainRestElements = document.querySelectorAll(
        '.sidebar:not(.sidebar-main):not(.sidebar-component)',
      )
      const sidebarMainDesktopToggler = document.querySelectorAll(
        '.sidebar-main-toggle',
      )
      const sidebarMainMobileToggler = document.querySelectorAll(
        '.sidebar-mobile-main-toggle',
      )
      const sidebarCollapsedClass = 'sidebar-collapsed'
      const sidebarMobileExpandedClass = 'sidebar-mobile-expanded'

      // On desktop
      sidebarMainDesktopToggler.forEach(function (toggler) {
        toggler.addEventListener('click', function (e) {
          e.preventDefault()
          sidebarMainElement.classList.toggle(sidebarCollapsedClass)
        })
      })

      // On mobile
      sidebarMainMobileToggler.forEach(function (toggler) {
        toggler.addEventListener('click', function (e) {
          e.preventDefault()
          sidebarMainElement.classList.toggle(sidebarMobileExpandedClass)

          sidebarMainRestElements.forEach(function (sidebars) {
            sidebars.classList.remove(sidebarMobileExpandedClass)
          })
        })
      })
    },

    // Toggle component sidebar
    sidebarComponentToggle() {
      // Elements
      const sidebarComponentElement =
        document.querySelector('.sidebar-component')
      const sidebarComponentMobileToggler = document.querySelectorAll(
        '.sidebar-mobile-component-toggle',
      )
      const sidebarMobileExpandedClass = 'sidebar-mobile-expanded'

      // Toggle classes
      sidebarComponentMobileToggler.forEach(function (toggler) {
        toggler.addEventListener('click', function (e) {
          e.preventDefault()
          sidebarComponentElement.classList.toggle(sidebarMobileExpandedClass)
        })
      })
    },

    // Sidebar navigation
    navigationSidebar() {
      // Elements
      const navContainerClass = 'nav-sidebar'
      const navItemOpenClass = 'nav-item-open'
      const navLinkClass = 'nav-link'
      const navLinkDisabledClass = 'disabled'
      const navSubmenuContainerClass = 'nav-item-submenu'
      const navSubmenuClass = 'nav-group-sub'
      const navScrollSpyClass = 'nav-scrollspy'
      const sidebarNavElement = document.querySelectorAll(
        `.${navContainerClass}:not(.${navScrollSpyClass})`,
      )

      // Setup
      sidebarNavElement.forEach(function (nav) {
        nav
          .querySelectorAll(
            `.${navSubmenuContainerClass} > .${navLinkClass}:not(.${navLinkDisabledClass})`,
          )
          .forEach(function (link) {
            link.addEventListener('click', function (e) {
              e.preventDefault()
              const submenuContainer = link.closest(
                `.${navSubmenuContainerClass}`,
              )
              const submenu = link
                .closest(`.${navSubmenuContainerClass}`)
                .querySelector(`:scope > .${navSubmenuClass}`)

              // Collapsible
              if (submenuContainer.classList.contains(navItemOpenClass)) {
                // eslint-disable-next-line no-undef
                new bootstrap.Collapse(submenu).hide()
                submenuContainer.classList.remove(navItemOpenClass)
              } else {
                // eslint-disable-next-line no-undef
                new bootstrap.Collapse(submenu).show()
                submenuContainer.classList.add(navItemOpenClass)
              }

              // Accordion
              if (
                link
                  .closest(`.${navContainerClass}`)
                  .getAttribute('data-nav-type') === 'accordion'
              ) {
                // eslint-disable-next-line no-restricted-syntax
                for (const sibling of link.parentNode.parentNode.children) {
                  if (
                    sibling !== link.parentNode &&
                    sibling.classList.contains(navItemOpenClass)
                  ) {
                    sibling
                      .querySelectorAll(`:scope > .${navSubmenuClass}`)
                      // eslint-disable-next-line no-loop-func, no-shadow
                      .forEach(function (submenu) {
                        // eslint-disable-next-line no-undef
                        new bootstrap.Collapse(submenu).hide()
                        sibling.classList.remove(navItemOpenClass)
                      })
                  }
                }
              }
            })
          })
      })
    },
  },
  mounted() {
    this.initSidebars()
    this.initNavigations()
  },
}
</script>
