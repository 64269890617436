import api from '@/services/api'

export default {
  state: {
    isLoggedPdCIn: !!localStorage.getItem('tokenPdC'),
    unity: !!localStorage.getItem('unityPdC'),
    user: {
      id: 0,
      name: '',
      unity: null,
    },
  },
  mutations: {
    AUTH(state, user) {
      state.isLoggedPdCIn = true
      state.unityPdC = JSON.stringify(user.unity)
      state.user = {
        id: user.id,
        name: user.name,
        unity: user.unity,
      }
    },

    LOGOUT(state) {
      state.isLoggedPdCIn = false
      state.unityPdC = null
      state.user = {
        id: 0,
        name: '',
        unity: null,
      }
    },
    SET_UNITY(state, unity) {
      localStorage.setItem('unityPdC', JSON.stringify(unity))
      state.unityPdC = JSON.stringify(unity)
      state.user.unity = unity
    },
    SET_FIREBASE_TOKEN(state, firebaseToken) {
      const unity = JSON.parse(localStorage.getItem('unityPdC'))
      unity.firebaseToken = firebaseToken
      localStorage.setItem('unityPdC', JSON.stringify(unity))
      state.unityPdC = JSON.stringify(unity)
      state.user.unity = unity
    },
  },
  actions: {
    async auth({ commit }, credentials) {
      try {
        const response = await api.post('clients/auth/login', {
          email: credentials.email,
          password: credentials.password,
        })

        const data = response && response.data ? response.data : null
        const token = data && data.token ? data.token : ''

        const user = {
          id: data && data.user ? data.user.id : 0,
          name: data && data.user ? data.user.name : '',
          unity: data && data.user ? data.user.unity : null,
        }
        localStorage.setItem('unityPdC', JSON.stringify(user.unity))
        localStorage.setItem('tokenPdC', token)
        commit('AUTH', user)
      } catch (error) {
        commit('LOGOUT')
        throw error
      }
    },
    async setUnity({ commit }, data) {
      const user = {
        id: data.id,
        name: data.name,
        unity: data.unity,
      }
      localStorage.setItem('unityPdC', JSON.stringify(user.unity))
      commit('AUTH', user)
    },
    async passwordRecovery({ commit }, email) {
      try {
        const response = await api.post('clients/auth/password/recovery', {
          email,
        })

        return response || null
      } catch (error) {
        throw error
      }
    },

    async newPassword({ commit }, data) {
      try {
        const response = await api.post('clients/auth/password/new', data)

        return response || null
      } catch (error) {
        throw error
      }
    },

    logout: ({ commit }) => {
      commit('LOGOUT')
    },
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },

  getters: {
    isLoggedPdCIn: (state) => state.isLoggedPdCIn,
    user: (state) => state.user,
    unity: (state) => state.user.unity,
    firebaseToken: (state) => state.user.unity.firebaseToken,
  },
}
