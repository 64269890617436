<template>
  <div class="offcanvas offcanvas-end" id="config-theme" tabindex="-1">
    <div class="position-absolute top-50 end-100 visible">
      <button
        type="button"
        class="btn btn-primary btn-icon translate-middle-y rounded-end-0"
        data-bs-toggle="offcanvas"
        data-bs-target="#config-theme"
        title="Configurações"
      >
        <i class="ph-gear"></i>
      </button>
    </div>

    <div class="offcanvas-header border-bottom py-0">
      <h5 class="offcanvas-title py-3">Configurações</h5>
      <button
        type="button"
        class="btn btn-light btn-sm btn-icon border-transparent rounded-pill"
        data-bs-dismiss="offcanvas"
        title="Fechar"
      >
        <i class="ph-x"></i>
      </button>
    </div>

    <div class="offcanvas-body">
      <div class="fw-semibold mb-2">Modo de Cor</div>
      <div class="list-group mb-3">
        <label
          class="list-group-item list-group-item-action form-check border-width-1 rounded mb-2 bg-primary bg-opacity-10 border-primary"
        >
          <div class="d-flex flex-fill my-1">
            <div class="form-check-label d-flex me-2">
              <i class="ph-sun ph-lg me-3"></i>
              <div>
                <span class="fw-bold">Tema claro</span>
                <div class="fs-sm text-muted">Mudar para o tema claro</div>
              </div>
            </div>
            <input
              type="radio"
              class="form-check-input cursor-pointer ms-auto"
              name="main-theme"
              value="light"
              checked="checked"
            />
          </div>
        </label>

        <label
          class="list-group-item list-group-item-action form-check border-width-1 rounded mb-2"
        >
          <div class="d-flex flex-fill my-1">
            <div class="form-check-label d-flex me-2">
              <i class="ph-moon ph-lg me-3"></i>
              <div>
                <span class="fw-bold">Tema escuro</span>
                <div class="fs-sm text-muted">Mudar para o tema escuro</div>
              </div>
            </div>
            <input
              type="radio"
              class="form-check-input cursor-pointer ms-auto"
              name="main-theme"
              value="dark"
            />
          </div>
        </label>
        <label
          class="list-group-item list-group-item-action form-check border-width-1 rounded mb-0"
        >
          <div class="d-flex flex-fill my-1">
            <div class="form-check-label d-flex me-2">
              <i class="ph-lightbulb ph-lg me-3"></i>
              <div>
                <span class="fw-bold">Automático</span>
                <div class="fs-sm text-muted">
                  Ajusta o tema de acordo com seu sistema
                </div>
              </div>
            </div>
            <input
              type="radio"
              class="form-check-input cursor-pointer ms-auto"
              name="main-theme"
              value="auto"
            />
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Configuration',
}
</script>
